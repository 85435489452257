



.gallery-container {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping to the next line */
    justify-content: center; /* Center the images */
    /* margin: 0 -10px;  */
  }
  
  .c {
    flex: 1 1 45%; /* Base width, grows and shrinks */
    margin: 10px; /* Space between items */
    max-width: 300px; /* Set a max width for individual images */
  }
  
  .custom-img-size {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Optional media query for smaller screens */
  @media (max-width: 768px) {
    .c {
      flex: 1 1 100%; /* Stack images in a single column on mobile */
      max-width: none; /* Remove max width on mobile */
    }
  }
  