


.col{

    align-items: end;
    padding-left: 47.2vw;
}

.service-image-wrapper{
    width: 90%;
    margin: 0 auto;
    text-align: center;         
}
.service-image{
width: 100%;

}


    .intro {
        background-image: url(https://img1.wsimg.com/isteam/ip/c18679ca-1b61-415c-b81f-8daecab60b63/Milling%201-min.jpg);
        background-size: cover; /* Cover ensures the image covers the entire container */
        background-position: center; /* Center the image in the container */
        height: 800px; /* Default height for larger screens */
        /* margin-top: 10px;  */
        background-attachment: fixed; /* Fixed attachment for desktop */
    }
    
    /* Media query for tablets and smaller devices */
    @media (max-width: 768px) {
        .intro {
            background-attachment: scroll; /* Change to scroll for better mobile performance */
            height: 500px; /* Adjust height for medium screens */
        }
    }
    
    /* Media query for mobile devices */
    @media (max-width: 480px) {
        .intro {
            background-size: cover; /* Maintain cover for consistency */
            height: 400px; /* Further reduce height for smaller screens */
        }
    }
    
    




    .bulle{
        margin-left: 0px;
        margin-top: 50px;
        text-decoration: none;
        

    }





    /* footer */

/* body {
    margin: 0;
    font-family: Arial, sans-serif;
    
  } */
  
  .ma-container {
    display: flex;
    /* justify-content: center;
    align-items: center; */
    height: 70vh;
    /* background-color: #f0f0f0; */
  }
  
  iframe {
    border: none;
    width: 700%;
    max-width: 800px;
    height: 450px;
  }

  .m{
width: 500px;
  }


  .fo{
    margin: 0;
    margin-right: 70px;
  }

  .t{
    text-align: center;
  }
  




/* map */

 
  .mcontainer-map {
    margin-top: 20px; /* Adjust margin as needed */
    width: 100%; /* Full width on mobile */
    max-width: 500px; /* Set a max width for larger screens */
  }
  
  .map {
    width: 100%; /* Full width for the iframe */
    height: 300px; /* Default height, adjust as needed */
    border: 0; /* No border */
    max-width: 600px;
  }
  
  /* Media query for desktop view */
  @media (min-width: 768px) {
    .d-flex {
      flex-direction: row; /* Change to row for desktop */
      /* justify-content: space-between;  */
      align-items: flex-start; /* Align items to the start */
    }
  
    .mcontainer-map {
      margin-top: 0; /* Remove top margin on desktop */
    }
  
    .map {
      height: 400px; /* Keep the height consistent on desktop */
    }
  }






/* services image  */

    
  







/* bullet */

  .bulle {
    padding: 20px; /* Add padding to the container */
  }
  
  .list-groupitem {
    list-style: none; /* Remove default list styling */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
  }
  
  .list-item {
    border: 1px solid #007bff; /* Optional: border for each item */
    margin-bottom: 10px; /* Space between items */
    border-radius: 5px; /* Rounded corners */
  }
  
  .link {
    display: block; /* Make the link block level for better clickability */
    padding: 10px; /* Add padding */
    color: #fff; /* Change text color */
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s; /* Smooth transition for hover */
  }
  
  /* Change background color on hover */
  .link:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .list-item {
      text-align: center; /* Center text on mobile */
    }
  }
  