/* .certificate{

    background-size: cover;
    height: 130vh; 
    width: 50vw; 
    justify-content: center;
    align-items: center;
    margin-left: 52vh;
    margin-top: 30px;
} */


@media (min-width: 992px) {
    .custom-img {
      max-width: 50%; /* Set a smaller width for desktop */
      height: auto; /* Ensure it scales properly */
    }
  }

  @media (max-width: 991px) {
    .custom-img {
      max-width: 100%; /* For mobile, the image takes full width */
      height: auto; /* Ensure it scales properly */
    }
  }