.col{

    padding-left: 1.5vh;
    padding-right: 5.9vw;
    /* margin: ; */
}
.nav-link:hover{
  color: white;
}
.navbar{
  background-color: #797272;
}







/* chat */

.App {
    position: relative;
    font-family: Arial, sans-serif;
  }
  
  .chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #10052b;
    color: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .chat-button.open {
    background-color: #4a5d77;
  }
  
  .chat-icon {
    font-size: 24px;
  }
  
  .close-icon {
    font-size: 24px;
  }
  
  .chat-form {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
  }
  
  .chat-form.dropup {
    opacity: 1;
    visibility: visible;
  }
  
  .chat-header {
    background-color: #001c3a;
    color: white;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .chat-content {
    padding: 20px;
  }
  
  .chat-content label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .chat-content input,
  .chat-content textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .send-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #001d3b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }
  
  .hide {
    display: none;
  }
  


  .privacy-note {
    text-align: center;
    font-size: 12px;
    margin: 10px 0;
  }




  .close-icon {
    color: white; /* Change to your desired color */
  }

  .btn-close {
    color: white; /* Change color as needed */
    background: none;
    border: none;
    font-size: 1.5rem; /* Adjust size */
  }


















