



.employees-wrapper{
  width: 90%;
  margin: 0 auto;
  /* font-family: 'Arial', sans-serif; */
  font-family:sans-serif;
}

  .title {
    text-align: center;
    margin-top: 4rem;
  }


  h2 {
    font-size: x-large;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    
  
  }

  @media (max-width: 480px) {
    .card-one-image{
order: 2;
    }
    .card-one-title{
      order: 1;

    }

      
    
  }