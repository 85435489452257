
.about-wrapper{
  width: 85%;
  margin: 0 auto;
}
  .img-fluid {
    width: 100%; 
    height: auto;
  }
.cardi{
  padding: 0rem 2rem 0rem 2rem;
  margin-top: 2rem;
}
  .Precision-title {
    text-align: left !important;
  }

  h1 {
    color: #ffc107; /* Bootstrap's warning color */
    font-size: xx-large;
  }

  h2 {
    font-size: x-large;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
   


    h1 {
      font-size: 20px;
    }

 
  }

  @media (max-width: 480px) {

    .about-wrapper{
      width: 95%;
      margin: 0 auto;
    }
    h1 {
      font-size: 30px !important;
    }

   
    .img-fluid {
      max-width: 100%;
    }
  }